@import '/src/styles/utilities.scss';

.banner {
  background-color: var(--color-alert-error-300);
}

.banner__content {
  @include create-container();

  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  padding: $space-8 0;
  color: var(--color-white);
  text-align: center;
}

.banner__link {
  @include text-button-small-effects('color-white');
}
